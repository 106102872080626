<template>

  <b-modal
      v-model="show"
      content-class="package-modal rounded-8"
      dialog-class="wameed-modal "
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('common.export_excel') }}
        </h3>
      </div>
    </template>


    <b-row>

      <b-col md="12" class="custom-date-model">


        <b-col md="12" class="mb-2">
          <span>{{ $t('statement.date.title') }}</span>
          <div class="statement-date-range" @click="showDatePickerModal=true">
            {{ formatDate(form.date_start,'YYYY-MM-DD') }}
            -
            {{ formatDate(form.date_end,'YYYY-MM-DD') }}
          </div>
        </b-col>


        <b-col cols="12" md="12" class="mb-2" >
          <wameed-dropdown
              :searchable="true"
              v-model="form.fields"
              :label="$t('statement.form.fields')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              :items="showFields"
              title="label"
              id="key"
              :multiple="true"
              :clearable="true"
              class="wameed_dropdown"
              rules="required"
              :no_options="$t('common.no_options')"
              :loadingMore="$t('common.loadingMore')"
              :no_options_search="$t('common.no_options_search')"
              @setSelectAll="whenSelectAll"
              :selectAll="selectAll"
              :selected-all-text="$t('form.all.selected')"
              :select-all-check-box="$t('form.all.checkBox')"
          />
        </b-col>


      </b-col>


    </b-row>

    <template slot="modal-footer">
      <wameed-btn
          classes="  text-med-14 text-white mx-0 rounded-8"
          :title="$t('btn.ok')"
          type="submit"
          variant="main"
          @onClick="submitOrder()"
      />
      <wameed-btn
          classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
          :title="$t('btn.cancel')"
          type="button"
          variant="gray"
          @onClick="closeModal()"
      />
    </template>


    <datepicker-modal
        :visible="showDatePickerModal"
        @close="showDatePickerModal = false"
        @onComplete="onDateRangeComplete"
    />
  </b-modal>


</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm';
import DatepickerModal from "@/views/pages/statement/datepickerModal.vue";
import moment from "moment";

moment.locale('en')
export default {
  components: {
    DatepickerModal,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,

  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },

    userType: {
      type: String,
      default: '',
    },
    userId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      date: null,
      selectAll: false,
      showDatePickerModal: false,

      form: {
        date_start: moment().startOf('month').format('YYYY-MM-DD').toLocaleString(),
        date_end: moment().endOf('month').format('YYYY-MM-DD').toLocaleString(),
        fields: [],
      }
    };
  },
  computed: {


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    showFields() {
      let _fields =
          this.fields.map((item) => {
            return {
              ...item,
              name: item.label,
              id: item.key,
            };
          });
      // remove the fields if the key = index
      return _fields.filter((item) => {
        return item.id !== 'index';
      });
    },

  },


  methods: {
    onDateRangeComplete(date) {
      this.form.date_start = date.start;
      this.form.date_end = date.end;
      this.showDatePickerModal = false;
    },

    whenSelectAll() {
      this.selectAll = !this.selectAll;
    },
    submitOrder() {
      let form = {
        ...this.form,
        user_id: this.userId,
        user_type: this.userType,
      }


      //get only the keys of the selected fields
      form.fields = form.fields.map((item) => {
        return item.id;
      });
      console.log(form, 'form')

      this.$store.dispatch('statement/exportExcel', form);

      this.$emit('onComplete', this.date);
      this.closeModal();


    },


    closeModal() {

      this.show = false;
    },
  },
};
</script>
<style>


</style>