<template>
  <b-card
      no-body
      v-if="personInfo"
      class="wameed-card m-0">

    <b-card-header class="align-items-start tab-title">
      <h5 class="text-med-18 text-font-main">
        {{ title }}
      </h5>
    </b-card-header>

    <div class="person-info">
      <div class="data">

        <template v-if="showImageDiv">
          <img v-if="personInfo.image && showImage" :src=personInfo.image :alt=personInfo.name @error="onError"
               class="person-img">
          <div v-else class="person-img">
            <span> <d-person-icon/> </span>
          </div>
        </template>

        <div class="col">
          <router-link class="name" :to="link" v-if="link!=null">{{ personInfo.name }}</router-link>
          <div class="phone">{{ personInfo.phone }}</div>
          <div class="phone">{{ personInfo.email }}</div>
          <div class="phone">{{ personInfo.gender }}</div>


        </div>
      </div>

    </div>


  </b-card>

</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm'
import MapModal from "@/components/mapModal.vue";

export default {
  components: {MapModal, WameedBtn},
  data() {
    return {
      showImage: true,
      openMap: false,
    }
  },
  props: {
    btn: String,
    title: String,
    link: {},
    btnColor: {
      type: String,
      default: 'secondary-btn'
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    enableBtn: {
      type: Boolean,
      default: true
    },
    info: null
  },
  computed: {
    personInfo() {
      return this.info;
    },
    showImageDiv() {
      return this.personInfo != null && Object.prototype.hasOwnProperty.call(this.personInfo, 'image');
    },

  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onClick() {
      this.$emit('onClick');
    },
    openMapModal() {
      this.openMap = true;
    }
  }

}
;
</script>
