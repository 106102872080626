<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal modal-sm "
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('statement.custom_date') }}
          </h3>
        </div>
      </template>


      <b-row>

        <b-col md="12" class="custom-date-model">
          <wameed-date-picker-modal
              v-model="date"
              :minDate="'1/1/1950'"
              :locale="$i18n.locale"
              :mode="'date'"
              :isRange="true"
              :config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"

          />


        </b-col>


      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.ok')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm';

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    DateRangePicker
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },


  },

  data() {
    return {
      date: null,
    };
  },
  computed: {


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

  },


  methods: {


    submitOrder() {

      this.$emit('onComplete', this.date);
      this.closeModal();


    },


    closeModal() {

      this.show = false;
    },
  },
};
</script>
<style>


</style>