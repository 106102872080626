<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.statement')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"

    />

    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">


            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5 p-6"
                >
                  <div class="d-flex flex-wrap">
                    <b-col md="6" class="pl-0">

                      <wameed-dropdown
                          v-model="form.type"
                          :label="$t('statement.type.title')"
                          placeholder="-------"
                          variant="disable"
                          :items="types"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"
                          @input="onTypeChange"
                          :no_options="$t('common.no_options')"
                      />

                    </b-col>
                    <!--                    date dropdown-->
                    <b-col md="6" class="pl-0">
                      <wameed-dropdown
                          v-model="form.date"
                          :label="$t('statement.date.title')"
                          placeholder="-------"
                          variant="disable"
                          :items="dateDropdown"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"
                          @input="onDateChange"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <!--                    users dropdown-->
                    <b-col md="6" class="pl-0">
                      <wameed-dropdown
                          v-model="form.user_id"
                          :label="$t('statement.user.title')"
                          placeholder="-------"
                          variant="disable"
                          :items="getUsers"
                          class="wameed_dropdown"
                          title="name"
                          :searchable="true"
                          rules="required"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col md="12" class="pl-0" v-if="showRangeDates">
                      <span>{{ $t('statement.date.title') }}</span>
                      <div class="statement-date-range" @click="showDatePickerModal=true">
                        {{ form.date_start }}
                        -
                        {{ form.date_end }}
                      </div>
                    </b-col>
                    <b-col md="12" class="pl-0">

                      <div class=" py-3">
                        <wameed-btn
                            classes="  text-med-14 text-white rounded-10"
                            :title="$t('statement.show_results')"
                            :disabled="invalid"
                            type="submit"
                            variant="main"
                        />
                      </div>
                    </b-col>

                  </div>

                </b-card-body>

              </div>
            </wameed-form>
          </b-card>
        </b-col>

        <b-col>

          <b-row v-if="getStatement.user && showData">

            <b-col class="mb-4" cols="12" md="6">

              <b-card
                  no-body class=" wameed-card  m-0 w-100 h-100" style='width:100%;height: 100%'>

                <b-card-header class="align-items-start tab-title">
                  <h5 class="text-med-18 text-font-main">
                    {{ $t('users.client.info') }}
                  </h5>
                </b-card-header>
                <div class="person-info">
                  <div class="data" style="align-items: center">


                    <img v-if="getStatement.user.image && showImage" :src=getStatement.user.image
                         :alt=getStatement.user.name @error="onError"
                         class="person-img">
                    <div v-else class="person-img">
               <span>
                  <d-person-icon/>
               </span>
                    </div>

                    <div class="col ">
                      <div class="name">{{ getStatement.user.name }}</div>
                      <div class="phone">{{ getStatement.user.category }}</div>


                    </div>

                  </div>

                </div>
                <div class="px-5 pb-2">

                  <div class="divider mb-2"></div>
                  <div class="tile-text between mb">
                    <div class="text">{{ $t('users.table.phone') }}</div>
                    <div class="value">{{ getStatement.user.phone }}</div>
                  </div>
                  <div class="tile-text between mb">
                    <div class="text">{{ $t('users.table.email') }}</div>
                    <div class="value">{{ getStatement.user.email }}</div>
                  </div>
                  <div class="tile-text between mb" v-if="getStatement.user.gender">
                    <div class="text">{{ $t('users.table.gender') }}</div>
                    <div class="value">{{ getStatement.user.gender }}</div>
                  </div>

                  <div class="tile-text between mb" v-if="getStatement.user.min_order_price">
                    <div class="text">{{ $t('statement.user.min_order') }}</div>
                    <div class="value">{{ formatNumber(getStatement.user.min_order_price, true) }}</div>
                  </div>


                </div>
              </b-card>
            </b-col>

            <b-col class="mb-4" cols="12" md="3">


              <financial-card
                  class="w-100 mb-4"
                  :number="getStatement.user.orders_count"
                  :title="$t('financial.card.total_orders')"
                  icon="f-orders2-icon"
                  :is_price="false"
              />
              <financial-card
                  class="w-100 "
                  :number="getStatement.user.total_sales"
                  :title="$t('statement.card.total_sales')"
                  icon="f-profit-plus-icon"
              />
            </b-col>

            <b-col class="mb-4" cols="12" md="3" v-if="showProfits">

              <financial-card
                  class="w-100 mb-4"
                  :number="getStatement.user.total_profit"
                  :title="$t('statement.card.total_profit')"
                  icon="f-profit3-icon"
              />


              <financial-card
                  class="w-100"
                  :number="getStatement.user.accounting"
                  :title="$t('statement.card.accounting')"
                  icon="f-profit4-icon"

              />
            </b-col>
          </b-row>
        </b-col>


      </b-row>

    </section>

    <accounting-table
        v-if="getStatement.user && showData"
        :formData="formData"
    />

    <datepicker-modal
        :visible="showDatePickerModal"
        @close="showDatePickerModal = false"
        @onComplete="onDateRangeComplete"
    />
  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import {WameedBtn, WameedDropdown, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import MealDetailModal from "@/views/pages/settings/general/components/mealDetailModal";
import i18n from "@/libs/i18n";
import {mapGetters} from "vuex";
import PersonInfoCard from "@/views/pages/statement/personInfoCard.vue";
import {permissions} from "@/enums/permissions.enum";
import FinancialCard from "@/components/financialCard.vue";
import AccountingTable from "@/views/pages/statement/accountingTable.vue";
import DatepickerModal from "@/views/pages/statement/datepickerModal.vue";

export default {
  components: {
    DatepickerModal,
    AccountingTable,
    FinancialCard,
    PersonInfoCard,
    MealDetailModal,
    WameedBtn,
    TextInput,

    WameedDropdown,

    PageHeader
  },
  data() {
    return {

      showData: false,
      showDatePickerModal: false,
      showImage: true,
      formData: {},
      form: {
        type: null,
        date: null,
        user_id: null,
        date_start: null,
        date_end: null
      },
      types: [
        {
          id: 2,
          name: i18n.t('statement.type.client')
        },
        {
          id: 3,
          name: i18n.t('statement.type.vendor')
        },
        {
          id: 4,
          name: i18n.t('statement.type.driver')
        }
      ],
      dateDropdown: [
        {
          id: 1,
          name: i18n.t('statement.date.today')
        },
        {
          id: 7,
          name: i18n.t('statement.date.last_7_days')
        },
        {
          id: 30,
          name: i18n.t('statement.date.last_30_days')
        },
        {
          id: 2,
          name: i18n.t('statement.date.custom')
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      getUsers: 'statement/getUsers',
      getStatement: 'statement/getStatement',
    }),

    userDetail() {
      if (this.getStatement.user == null) return null;
      if (this.form.type == null) return null;
      let userId = this.getStatement.user.id;
      if (this.form.type.id === 4)
        return {
          name: 'users-drivers-detail',
          params: {lang: this.$i18n.locale, id: userId},
        };
      if (this.form.type.id === 2) {
        let permission = this.$store.getters['profile/getPermissions']
        let readClient = permissions.readClientsInfo;
        let readClientOrders = permissions.readClientsOrders;

        if (permission.includes(readClient)) {

          return {
            name: 'users-clients-detail',
            params: {lang: this.$i18n.locale, id: userId},
          };
        } else if (permission.includes(readClientOrders)) {
          return {
            name: 'users-clients-orders',
            params: {lang: this.$i18n.locale, id: userId},
          };
        }
      }
      if (this.form.type.id === 3)
        return {
          name: 'users-vendors-detail',
          params: {lang: this.$i18n.locale, id: userId},
        };

    },

    showProfits() {
      return this.form.type && this.form.type.id !== 2;
    },

    showRangeDates(){
      return this.form.date_start && this.form.date_end;
    }
  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onTypeChange(type) {
      this.showData = false;
      this.form.user_id = null;
      this.$store.dispatch('statement/getUsers', type.id);
      this.$store.dispatch('statement/getFilters', type.id);

    },

    onDateChange(date) {
      //reset date range
      this.form.date_start = null;
      this.form.date_end = null;

      if (date.id == 2)
        this.showDatePickerModal = true
    },

    goBack() {
      this.$router.push({name: 'settings'});
    },
    onDateRangeComplete(date) {
      this.form.date_start = date.start;
      this.form.date_end = date.end;
      this.showDatePickerModal = false;
    },

    async submit() {
      let data = {
        type: this.form.type.id,
        date: this.form.date.id,
        user_id: this.form.user_id.id,
        date_start: this.form.date_start,
        date_end: this.form.date_end
      }
      this.formData = data;
      this.showData = true;
      await this.$store.dispatch('statement/getStatement', data);
    }
  }
  ,

}
;
</script>

