<template>
  <div class="status-container " :class="cssClass">
    <span class="status-container--text text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    operation: null,
  },

  computed: {
    text() {
      switch (this.operation) {
        case '+':
          return this.$t('statement.table.+');
        default:
          return this.$t('statement.table.-');
      }
    },

    cssClass() {
      switch (this.operation) {
        case '+':
          return 'green';
        default:
          return 'red'
      }
    },


  }
}
</script>

