<template>
  <div class="wameed-dashboard-page-content">

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @orderApplicants="orderData"
          @resetFilter="resetFilter"


      >
        <template v-slot:btns>

          <div class="ml-2 mt-3" v-if="formData.type!=2">
            <div
                @click="showOperationModel = true"
                style="display: flex;justify-content: center;align-items: center"
                class=" btn wameed-btn add-button cursor-pointer search-form-control button-header-create"
            >
              <span class="mx-2"> {{ $t('statement.add_payment_amount') }} </span>
            </div>
          </div>

          <div class="outline-link ml-2 mt-3" @click="()=>showExcelModel=true">
            <o-export-icon/>
            {{ $t('common.export_excel') }}
          </div>
        </template>
      </filter-header>
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'date' },
                { name: 'action' },
                { name: 'service_vendor' },
                { name: 'operation' },
                { name: 'total_amount' },
              ]"
            >

              <template slot="date" slot-scope="{ data }">
                <span class="text-regular-14 text-font-secondary">
                  {{ formatDate(data.item.date, 'YYYY-MM-DD') }}

                </span>
              </template>

              <template slot="operation" slot-scope="{ data }">
                <operation-status-badge :operation="data.item.operation"/>
              </template>

              <template slot="total_amount" slot-scope="{ data }">
                <span class="text-regular-14 text-font-secondary" v-if="data.item.financial_operations_price">
                  {{ data.item.financial_operations_price }}
                </span>
                <span class="text-regular-14 text-font-secondary" v-else>
                  {{ data.item.order_price }}
                </span>
              </template>
              <template slot="service_vendor" slot-scope="{ data }">
                <span class="text-regular-14 text-font-secondary" v-if="data.item.service">
                  {{ data.item.service }}
                </span>
                <span class="text-regular-14 text-font-secondary" v-if='data.item.vendor_name'>
                   / {{ data.item.vendor_name }}
                </span>
              </template>

            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              :items="paginationDropDownItems"
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

    <add-operation-modal
        :visible="showOperationModel"
        @close="showOperationModel = false"
        :userId="formData.user_id"
        @onComplete="loadData"
    />

    <statement-export-modal
        v-if="showExcelModel"
        :visible="showExcelModel"
        :userId="formData.user_id"
        :userType="formData.type"
        @close="showExcelModel = false"
        :fields="tableFields"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import WarningModal from "@/components/WarningModal";
import CheckValue from "@/components/checkValue";
import CheckPrice from "@/components/checkPrice";
import NameTile from "@/components/nameTile.table";
import ImportExcelModal from "@/components/importExcelModal";
import OperationStatusBadge from "@/views/pages/statement/operationStatusBadge.vue";
import AddOperationModal from "@/views/pages/users/addOperationModal.vue";
import StatementExportModal from "@/views/pages/statement/statementExportModal.vue";

export default {
  components: {
    StatementExportModal,
    AddOperationModal,
    OperationStatusBadge,
    ImportExcelModal,
    NameTile,
    CheckPrice,
    CheckValue,
    WarningModal,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,
  },
  props: {
    formData: {},
  },
  data() {
    return {
      vendor_id: '',
      showExcelModel: false,
      showOperationModel: false,
      showModal: false,
      importModal: false,
      deleteModal: false,
      currentItemData: null,
      sortable: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'order',
        search: '',
      },

    };
  },
  computed: {
    ...mapGetters({
      getData: 'statement/getStatementAccounting',
      getTotal: 'statement/getTotal',

      getFilters: 'statement/getFilters',
    }),
    filterContent() {
      let user_type = this.formData.type;
      if (user_type == 2)
        return [
          {
            title: this.$i18n.t('statement.filters.types'),
            key: 'filter_types',
            id: 'id',
            type: 'text',
            name: 'title',
            selectedData: [],
            translate: true,
            data: this.getFilters,
          },
        ]
      return [
        {
          title: this.$i18n.t('statement.filters.operation'),
          key: 'filter_operation',
          id: 'id',
          type: 'text',
          name: 'title',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'plus',
              title: this.$i18n.t('statement.table.+'),
            },
            {
              id: 'minus',
              title: this.$i18n.t('statement.table.-'),
            },
          ],
        },
      ];
    },
    tableFields() {
      let user_type = this.formData.type;
      if (user_type == 2)
        return [
          {key: "index", label: "#",},
          {key: 'order_no', label: this.$i18n.t('orders.table.no'), tdClass: 'w-1/8'},
          {key: 'tran_ref', label: this.$i18n.t('statement.table.tran_ref'), tdClass: 'w-2/8'},
          {key: 'service_vendor', label: this.$i18n.t('statement.table.service_vendor'), tdClass: 'w-1/8'},
          {key: 'payed_amount', label: this.$i18n.t('statement.table.payed_amount'), tdClass: 'w-1/8'},
          {key: 'date', label: this.$i18n.t('statement.table.date'), tdClass: 'w-1/8'},
        ];
      if(user_type == 4)
        return [
          {key: "index", label: "#",},
          {key: 'order_no', label: this.$i18n.t('orders.table.no'), tdClass: 'w-1/8'},
          {key: 'tran_ref', label: this.$i18n.t('statement.table.tran_ref'), tdClass: 'w-2/8'},
          {key: 'service_vendor', label: this.$i18n.t('statement.table.service_vendor'), tdClass: 'w-1/8'},
          {key: 'total_amount', label: this.$i18n.t('statement.table.total_amount'), tdClass: 'w-1/8'},
          {key: 'date', label: this.$i18n.t('statement.table.date'), tdClass: 'w-1/8'},
          {key: 'operation', label: this.$i18n.t('statement.table.operation'), tdClass: 'w-1/8'},
          {key: 'note', label: this.$i18n.t('statement.table.note'), tdClass: 'w-1/8'},
        ];

      return [
        {key: "index", label: "#",},
        {key: 'order_no', label: this.$i18n.t('orders.table.no'), tdClass: 'w-1/8'},
        {key: 'tran_ref', label: this.$i18n.t('statement.table.tran_ref'), tdClass: 'w-2/8'},
        {key: 'total_amount', label: this.$i18n.t('statement.table.total_amount'), tdClass: 'w-1/8'},
        {key: 'date', label: this.$i18n.t('statement.table.date'), tdClass: 'w-1/8'},
        {key: 'operation', label: this.$i18n.t('statement.table.operation'), tdClass: 'w-1/8'},
        {key: 'note', label: this.$i18n.t('statement.table.note'), tdClass: 'w-1/8'},
      ];
    },


    paginationDropDownItems() {
      return [
        {
          title: `20 - 1 `,
          id: 20,
        },
        {
          title: `40 - 1 `,
          id: 40,
        },
        {
          title: `60 - 1 `,
          id: 60,
        },
        {
          title: this.$i18n.t('common.all'),
          id: this.getTotal.totalItems,
        },
      ];
    }
  },

  methods: {
    ...mapActions({
      _getData: 'statement/getStatement',
    }),


    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      console.log(this.formData)
      this._getData({...this.filterData, vendor_id: this.vendor_id, ...this.formData});
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      console.log(this.filterContent)
      this.loadData();
    },

    orderData(item) {

      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


  },

};
</script>
